/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// ----------------- NAVBAR -------------------
var target = window.location.hash,
    target = target.replace("#", "");
window.location.hash = "";

$(window).on("load", function() {
    if (target) {
        $("html, body").animate(
            {
                scrollTop: $("#" + target).offset().top - 200
            },
            800
        );
    }
});

$(".nav-link").click(function() {
    var sectionTo = $(this).attr("data-href");
    if ($(sectionTo).length !== 0) {
        var top = $(sectionTo).offset().top - 200;
        $("html, body").animate(
            {
                scrollTop: top
            },
            800
        );
    }
});

const sections = document.getElementsByClassName("nav-anchor-welcome");
const sectionList = Array.from(sections);
// console.log("list", sectionList);
const navLi = document.querySelectorAll("nav .container ul li");
// console.log("nav", navLi);
if (sections.length === 0) {
    navLi.forEach(li => {
        li.classList.remove("active");
    });
}

window.addEventListener("scroll", () => {
    let current = "";
    sectionList.forEach(section => {
        const sectionTop = section.offsetTop - 200;
        const sectionHeight = section.clientHeight;
        if (pageYOffset >= sectionTop - sectionHeight / 3) {
            current = section.getAttribute("id");
        }
    });

    navLi.forEach(li => {
        li.classList.remove("active");
        if (li.classList.contains(current)) {
            li.classList.add("active");
        }
    });
});

// -------------------- MODELS -------------------
let buttons = new Array();
buttons.left = document.getElementsByClassName("slider-left");
buttons.right = document.getElementsByClassName("slider-right");
numberOfCards = buttons.left.length;
let actualCard = 0;
let cardWidth;

if (document.getElementsByClassName("model-card").length !== 0) {
    // console.log("model-card");
    const cards = document.getElementsByClassName("model-card");
    const card = cards[0];
    cardWidth = card.offsetWidth + 48;
    actualCard = 0;

    setFlex(buttons.right[0]);

    for (let i = 0; i < numberOfCards; i++) {
        buttons.left[i].onclick = function() {
            scrollLeft();
            // console.log("scroll left");
        };
        buttons.right[i].onclick = function() {
            scrollRight();
            // console.log("scroll right");
        };
    }
}

function scrollRight() {
    movePicturesRight();
    if (actualCard < numberOfCards - 2) {
        setNone(buttons.left[actualCard]);
        setNone(buttons.right[actualCard]);
        setFlex(buttons.left[actualCard + 1]);
        setFlex(buttons.right[actualCard + 1]);
        actualCard++;
    } else if (actualCard === numberOfCards - 2) {
        setNone(buttons.left[actualCard]);
        setNone(buttons.right[actualCard]);
        setFlex(buttons.left[actualCard + 1]);
        actualCard++;
    }
}

function scrollLeft() {
    movePicturesLeft();
    if (actualCard > 1) {
        setNone(buttons.left[actualCard]);
        setNone(buttons.right[actualCard]);
        setFlex(buttons.left[actualCard - 1]);
        setFlex(buttons.right[actualCard - 1]);
        actualCard--;
    } else if (actualCard === 1) {
        setNone(buttons.left[actualCard]);
        setNone(buttons.right[actualCard]);
        setFlex(buttons.right[actualCard - 1]);
        actualCard--;
    }
}

function movePicturesRight() {
    document.getElementById("models").scrollLeft += cardWidth;
}

function movePicturesLeft() {
    document.getElementById("models").scrollLeft -= cardWidth;
}

function setNone(button) {
    button.style.display = "none";
}

function setFlex(button) {
    button.style.display = "flex";
}

let exam = document.getElementById("exam");
if (exam) {
    let element = document.getElementById("videos-knowledge");
    element.style.backgroundPositionY = "70%";
}

// SEARCH
$(".search-img").click(function() {
    $("#navbar-nav").hide();
    $("#search-field").show();
});

$(".close-img").click(function() {
    $("#navbar-nav").show();
    $("#search-field").hide();
});
